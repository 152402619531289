@font-face {
  font-family: 'Prompt';
  src: url('../fonts/Prompt-Regular.ttf'); /* IE9 Compat Modes */
  /*src: url('../assets/fonts/Prompt-Regular.ttf'); !* IE9 Compat Modes *!*/
}

@font-face {
  font-family: 'Prompt-Medium';
  src: url('../fonts/Prompt-Medium.ttf'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'Prompt-Thin';
  src: url('../fonts/Prompt-Thin.ttf'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'Prompt-Bold';
  src: url('../fonts/Prompt-Bold.ttf'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/roboto/Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/roboto/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/roboto/Roboto-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Sarabun';
  src: url('../fonts/sarabun/Sarabun-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Sarabun';
  src: url('../fonts/sarabun/Sarabun-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Sarabun';
  src: url('../fonts/sarabun/Sarabun-SemiBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

* {
  box-sizing: border-box;
}

html {
  overflow-x: hidden;
  overflow-y: overlay;
  --overflow: overlay;
}

body {
  margin: 0;
  font-family: 'Roboto', 'Sarabun';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f4f7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.space-between {
  justify-content: space-between;
}

.flex-grow {
  flex-grow: 1;
}

.text-center {
  text-align: center;
}

.text-underline {
  text-decoration: underline;
}

.text-link {
  text-decoration: underline;
  color: #29aa68;
}

.bold-text {
  font-weight: 700;
}

.cursor-pointer {
  cursor: pointer;
}
